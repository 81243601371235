<template>
  <div class="infoPage">
<!--    <div class="pageNav">-->
<!--      <div class="nav_left">-->
<!--        <span class="zh">度假区导览</span>-->
<!--        <span class="en">Resort Tour</span>-->
<!--      </div>-->
<!--      <div class="nav_right">-->
<!--        <div class="n_r_title">您当前的位置:</div>-->
<!--        <el-breadcrumb separator-class="el-icon-arrow-right">-->
<!--          <el-breadcrumb-item>首页</el-breadcrumb-item>-->
<!--          <el-breadcrumb-item v-if="navid == 0">度假区概况</el-breadcrumb-item>-->
<!--          <el-breadcrumb-item v-if="navid == 1">度假区总览图</el-breadcrumb-item>-->
<!--          <el-breadcrumb-item v-if="navid == 2">交通指引</el-breadcrumb-item>-->
<!--        </el-breadcrumb>-->
<!--      </div>-->
<!--    </div>-->
    <div class="right">
<!--      <div class="right_1">-->
<!--        <div :class="navid==index?'zhong':'wei'" @click="navid=index" class="right_2" v-for="(item,index) of navlist" :key="index">{{item}}</div>-->
<!--      </div>-->
      <gaikuang v-if="navid==0?true:false" />
      <lantu v-if="navid==1?true:false" />
<!--      <jiaotong v-if="navid==2?true:false" />-->
      <jiaotongTwo v-if="navid==2?true:false" />
      <virtual v-if="navid==3?true:false" />
    </div>
<!--    <div class="paging-device">-->
<!--      <el-pagination-->
<!--          layout="prev, pager, next"-->
<!--          @size-change="handleSizeChange"-->
<!--          @current-change="handleCurrentChange"-->
<!--          :current-page="current_page"-->
<!--          :page-size="per_page"-->
<!--          :total="totalCount">-->
<!--      </el-pagination>-->
<!--    </div>-->
  </div>
</template>

<script>
// import {} from "@/api";
import gaikuang from './gaikuang'
import lantu from './lantu'
import jiaotong from './jiaotong'
import jiaotongTwo from './jiaotong2'
import virtual from './virtual'
export default {
  name: "index",
  components:{
    gaikuang,
    lantu,
    jiaotong,
    jiaotongTwo,
    virtual
  },
  data(){
    return {
      totalCount: 0,
      current_page: 1,
      per_page: 4,
      datalist:[],
      navlist:['度假区概况','度假区总览图','交通指引'],
      navid:0,
    }
  },
  watch:{
    $route(to,form) {
      // this.number++
      // console.log(to,form)
      if(to.query.type){
        if(form.query.type != to.query.type){
          this.navid = to.query.type
        }
      }else{
        this.navid = '0'
      }
      // this.$router.go(0)
    },
    // deep: true
  },
  created() {
    if(this.$route.query.type){
      this.navid = this.$route.query.type
    }
    // this.navid=this.$route.query.type
    // sessionStorage.setItem('RightImg','/img/swiperPage/DTZXCBT.png')
  },
  mounted() {
    // this.createlist()
  },
  methods:{

    // 每页条数
    handleSizeChange(val) {
      this.per_page=val
      this.createlist()
    },
    // 页数
    handleCurrentChange(val) {
      this.current_page=val
      this.createlist()
    },
    createlist(){
      // 获取数据
    },
  }
}
</script>

<style scoped lang="less">
.zhong{
  background: rgba(51, 125, 141, 0.15);
  color: rgba(51, 125, 141, 1);
}
.wei{
  background: rgba(170, 170, 170, 0.15);
  color: rgba(51, 51, 51, 1);
}
.infoPage{
  width: 100%;
  //height: calc(100vh - 130px);
  //background: skyblue;
  //padding: 0 0 0 55px;
  .pageNav{
    height: 88px;
    border-bottom: 1px solid #E0E0E0;
    margin-bottom: 50px;
    padding: 0 110px 0 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .nav_left{
      .zh{
        font-size: 24px;
        font-family: STSongti-SC-Black, STSongti-SC;
        font-weight: 900;
        color: #313131;
        //line-height: 33px;
        margin-right: 12px;
      }
      .en{
        font-size: 20px;
        font-family: STSongti-SC-Regular, STSongti-SC;
        font-weight: 400;
        color: #313131;
        //line-height: 28px;
      }
    }
    .nav_right{
      display: flex;
      //justify-content: center;
      .n_r_title{
        font-size: 18px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #313131;
        line-height: 25px;
        margin-right: 20px;
      }
      /deep/.el-breadcrumb{
        font-size: 18px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #313131;
        line-height: 25px;
      }
    }
  }
  .right{
    //width: 1430px;
    //height: 800px;
    //overflow: scroll;
    ////background: skyblue;
    //&::-webkit-scrollbar {
    //  width: 0px;
    //}
    margin-bottom: 130px;
    .right_1{
      width: 792px;
      height: 70px;
      display: flex;
      justify-content: space-between;
      margin: 30px auto;
      .right_2{
        width: 224px;
        height: 70px;
        text-align: center;
        line-height: 70px;
        font-size: 26px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        cursor: pointer;
      }
    }
  }
  /deep/.paging-device{
    margin: 20px 0;
    display: flex;
    justify-content: center;
    .el-pager li{
      height: 35px;
      line-height: 35px;
    }
    .btn-prev{
      border-radius: 50%;
      box-shadow: 0px 3px 15px 2px rgba(235, 235, 235, 0.5);
      padding: 0;
      display: initial;
      width: 35px;
      height: 35px;
      margin-right: 40px;
      i{
        font-size: 16px;
      }
    }
    .btn-next{
      border-radius: 50%;
      box-shadow: 0px 3px 15px 2px rgba(235, 235, 235, 0.5);
      padding: 0;
      display: initial;
      width: 35px;
      height: 35px;
      margin-left: 40px;
      i{
        font-size: 16px;
      }
    }
  }
}
</style>

